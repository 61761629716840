import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
    Accueil: "/",
    "Politique de Confidentialité": "/fr/politique-de-confidentialité"
  };
  
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/privacy-policy"
);

  const PrivacyPolicy = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title="Politique de Confidentialité"
          description="Détails sur ma politique de protection des données visant à expliquer comment mes services fonctionnent et comment la protection de vos données personnelles est garantie."
          lang="fr"
          alternateLangs={alternateLangs}
          meta={[{ name: "robots", content: "noindex" }]}
        />
        <MainContent article>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
  
          <H as="h1">Protection des données</H>
          <p>Politique de protection des données</p>
        <p>
          Ci-dessous, j'aimerais expliquer quelles données je collecte, traite et utilise, quand et dans quel but. L'objectif est de
          vous expliquer comment fonctionnent mes services et comment la protection de vos données personnelles est garantie à
          cet égard.
        </p>
        <p>Je ne collecte, traite et utilise des données personnelles que si vous y avez consenti ou si la législation le permet.</p>
        <p>
          Cette politique de protection des données peut être téléchargée, sauvegardée et imprimée à tout moment via l'URL
          https://matthiaskupperschmidt.com/privacy-policy/.
        </p>
        <H as="h2">Nom et adresse du responsable</H>
        <p>Le Responsable au sens de la législation sur la protection des données est :</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Copenhague, Danemark</p>
        <p>Vous pouvez me contacter à tout moment par email à legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Nom et adresse du Délégué à la Protection des Données</H>
        <p>Coordonnées du Délégué à la Protection des Données :</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Copenhague, Danemark</p>
        <p>Vous pouvez me contacter à tout moment par email à legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Points généraux sur la protection des données</H>
        <p>Portée du traitement des données personnelles</p>
        <p>
          Je ne traite les données personnelles des utilisateurs que si nécessaire pour fournir un site web opérationnel ainsi que des contenus
          et des services. En règle générale, les données personnelles des utilisateurs ne sont traitées qu'après que l'utilisateur a donné son consentement. Une exception
          s'applique dans les cas où le consentement ne peut être obtenu au préalable pour des raisons factuelles, et le traitement des
          données est autorisé par des réglementations légales.
        </p>
        <H as="h2">Base légale pour le traitement des données personnelles</H>
        <p>
          L'article 6 (1, a) du Règlement Général sur la Protection des Données (RGPD) de l'UE sert de base légale lorsque j'obtiens
          le consentement de la personne concernée pour traiter ses données personnelles.
        </p>
        <p>
          L'article 6 (1, b) RGPD sert de base légale lorsque le traitement des données personnelles est nécessaire pour
          l'exécution d'un contrat auquel la personne concernée est partie. Cela s'applique également au traitement
          nécessaire pour la mise en œuvre de mesures précontractuelles.
        </p>
        <p>
          L'article 6 (1, c) RGPD sert de base légale lorsque le traitement des données personnelles est nécessaire pour
          remplir une obligation légale à laquelle mon entreprise est soumise.
        </p>
        <p>
          Lorsque le traitement est nécessaire pour sauvegarder un intérêt légitime de mon entreprise ou d'un tiers, et que
          les intérêts et droits fondamentaux de la personne concernée ne l'emportent pas sur le premier intérêt mentionné,
          l'article 6 (1, f) RGPD sert de base légale pour le traitement.
        </p>

        <H as="h2">Effacement des données et durée de stockage</H>
        <p>
          Les données personnelles de la personne concernée sont effacées ou bloquées dès que la finalité du stockage cesse d'exister.
          Le stockage peut également avoir lieu si les législateurs européens ou nationaux le prévoient dans des règlements, lois ou autres
          exigences du droit de l'UE auxquels le Responsable est soumis. Les données sont également supprimées ou effacées si une période de stockage
          prescrite par la législation mentionnée a expiré, sauf s'il y a une obligation de conserver les données pour conclure ou exécuter un contrat.
        </p>
        <p>Types de données traitées :</p>
        <p>– Coordonnées (par exemple, email, nom).</p>
        <p>– Données d'utilisation (par exemple, pages visitées, intérêt pour le contenu, heures d'accès).</p>
        <p>– Données méta/communications (par exemple, informations sur l'appareil, adresses IP).</p>
        <H as="h2">Catégories de personnes concernées</H>
        <p>Visiteurs et utilisateurs de l'offre en ligne, tous résumés comme "utilisateurs" ci-après.</p>
        <H as="h2">But du traitement</H>
        <p>– Fourniture de l'offre en ligne, de ses fonctions et de son contenu.</p>
        <p>– Répondre aux demandes de contact et communication avec les utilisateurs.</p>
        <p>– Mesures de sécurité.</p>
        <p>– Évaluation de l'audience/marketing</p>
        <H as="h3">Définitions utilisées</H>
        <p>
          Les "données personnelles" désignent toute information se rapportant à une personne physique identifiée ou identifiable ("personne concernée") ; une personne physique identifiable est une personne qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne (par exemple, un cookie) ou à un ou plusieurs éléments spécifiques à l'identité physique, physiologique, génétique, mentale, économique, culturelle ou sociale de cette personne physique.
        </p>
        <p>
          Le "traitement" désigne toute opération ou ensemble d'opérations effectuées sur des données personnelles ou sur des ensembles de données personnelles, que ce soit ou non par des moyens automatisés. Le terme est très large et couvre pratiquement toute manipulation de données.
        </p>
        <p>
          Le "responsable du traitement" désigne la personne physique ou morale, l'autorité publique, l'agence ou tout autre organisme qui, seul ou conjointement avec d'autres, détermine les finalités et les moyens du traitement des données personnelles.
        </p>
        <H as="h2">Bases légales pertinentes</H>
        <p>
          Conformément à l'article 13 RGPD, je vous informe des bases légales de notre traitement des données. Ce qui suit s'applique lorsque la base légale n'est pas indiquée dans la politique de protection des données : La base légale pour obtenir le consentement est l'article 6 (1, a) et l'article 7 RGPD, la base légale pour le traitement afin d'exécuter nos services et mettre en œuvre des mesures contractuelles, ainsi que pour répondre aux demandes est l'article 6 (1, b) RGPD, la base légale pour le traitement afin de respecter nos obligations légales est l'article 6 (1, c) RGPD, et la base légale pour le traitement afin de sauvegarder nos intérêts légitimes est l'article 6 (1, f) RGPD.
        </p>
        <H as="h2">Collaboration avec des sous-traitants et des tiers</H>
        <p>
          Lorsque, lors du traitement, je divulgue des données à d'autres parties et entreprises (sous-traitants ou tiers), ou accorde un accès direct ou autre aux données, cela ne se produit que sur la base d'une autorisation légale (par exemple, si le transfert de données à des tiers, comme un prestataire de services de paiement, est nécessaire pour exécuter un contrat conformément à l'article 6 (1, b) RGPD, vous avez donné votre consentement, une obligation légale est en place, ou sur la base de nos intérêts légitimes (par exemple, lors de l'utilisation d'agents, d'hébergeurs web, etc.).
        </p>
        <p>
          Lorsque je commande à des tiers le traitement de données sur la base d'un soi-disant "contrat de traitement", cela se produit sur la base de l'article 28 RGPD.
        </p>
        <H as="h2">Transmission à des pays tiers</H>
        <p>
          Lorsque je traite des données dans un pays tiers (c'est-à-dire en dehors de l'Union Européenne (UE) ou de l'Espace Économique Européen (EEE)), ou cela se produit lors de l'utilisation de services tiers ou de la divulgation ou, le cas échéant, du transfert de données à des tiers, cela ne se produit que si cela est nécessaire pour exécuter nos obligations (pré-)contractuelles, sur la base de votre consentement, en raison d'une obligation légale, ou sur la base de nos intérêts légitimes. Sous réserve d'autorisation légale ou contractuelle, je ne traite, ou n'organise la traitement des données dans un pays tiers que lorsque les exigences spécifiques des articles 44 et suivants RGPD sont remplies. En d'autres termes, le traitement se fait, par exemple, sur la base de garanties particulières telles que la détermination officiellement reconnue du niveau de protection des données correspondant à l'UE (par exemple, pour les États-Unis par le "Privacy Shield") ou le respect des obligations spécifiques officiellement reconnues (soi-disant "clauses contractuelles types").
        </p>
        <H as="h2">Droits des personnes concernées</H>
        <p>
          Vous avez le droit d'exiger une confirmation quant à savoir si les données en question sont traitées et d'obtenir des informations sur ces données, ainsi que des informations supplémentaires et une copie des données conformément à l'article 15 RGPD.
        </p>
        <p>
          Conformément à l'article 16 RGPD, vous avez le droit d'exiger que les données vous concernant soient complétées, ou les données incorrectes vous concernant rectifiées.
        </p>
        <p>
          Conformément à l'article 17 RGPD, vous avez le droit d'exiger l'effacement des données vous concernant sans délai indu ou, alternativement, conformément à l'article 18 RGPD, un traitement restreint des données.
        </p>
        <p>
          Vous avez le droit d'exiger la réception des données que vous m'avez fournies conformément à l'article 20
          RGPD, et de demander leur transmission à un autre responsable du traitement.
        </p>
        <p>
          Vous avez également le droit, conformément à l'article 77 RGPD, de déposer une plainte auprès de l'autorité
          de contrôle compétente.
        </p>
        <H as="h2">Révocation</H>
        <p>Vous avez le droit de révoquer le consentement accordé conformément à l'article 7 (3) RGPD avec effet futur.</p>
        <H as="h2">Droit d'opposition</H>
        <p>
          Conformément à l'article 21 RGPD, vous pouvez vous opposer au traitement futur des données vous concernant. En
          particulier, l'opposition peut être faite contre le traitement à des fins de publicité directe.
        </p>
        <H as="h2">Cookies et droit d'opposition à la publicité directe</H>
        <p>
          Les cookies désignent de petits fichiers stockés sur les ordinateurs des utilisateurs. Différents détails peuvent être stockés dans les cookies.
          Un cookie sert principalement à stocker des informations sur un utilisateur (ou, le cas échéant, sur l'appareil sur lequel le cookie est
          stocké) pendant ou après sa visite dans une offre en ligne. Les cookies qui sont supprimés après qu'un utilisateur
          quitte une offre en ligne et ferme son navigateur sont appelés cookies temporaires ou, le cas échéant,
          cookies de session. Par exemple, un statut de connexion peut être stocké dans un tel cookie. Les cookies qui restent même
          après la fermeture du navigateur sont appelés cookies permanents ou persistants. Par exemple, cela permet
          de stocker le statut de connexion si l'utilisateur le visite après plusieurs jours. Les intérêts des utilisateurs pour mesurer l'audience
          ou à des fins de marketing peuvent également être stockés dans un tel cookie. Les cookies proposés par des prestataires autres que le responsable
          du traitement opérant l'offre en ligne sont appelés cookies tiers (sinon cookies propriétaires si seuls ses cookies existent).
        </p>
        <p>Je peux utiliser des cookies temporaires et permanents, et clarifier cela dans notre politique de protection des données.</p>
        <p>
          Si les utilisateurs ne souhaitent pas que des cookies soient stockés sur leur ordinateur, ils sont invités à désactiver l'option correspondante dans les paramètres du système.
          Les cookies stockés peuvent être supprimés dans les paramètres du système du navigateur.
          L'exclusion des cookies peut entraîner un fonctionnement restreint de cette offre en ligne.
        </p>
        <p>
          Une objection générale à l'utilisation de cookies placés à des fins de marketing en ligne peut être déclarée auprès d'un
          large éventail de services, notamment en cas de suivi, via le site américain
          http://www.aboutads.info/choices/ ou le site de l'UE http://www.youronlinechoices.com/. En outre, le stockage
          de cookies peut être réalisé en les désactivant dans les paramètres du navigateur. Veuillez noter que toutes les
          fonctions de cette offre en ligne ne peuvent alors pas être utilisées.
        </p>
        <p>
          Lors de l'accès à notre site web, les utilisateurs sont informés de l'utilisation de cookies à des fins d'analyse par une bannière d'information renvoyant à la politique de protection des données. À cet égard, il est également fait référence à la manière dont
          le stockage des cookies peut être empêché dans les paramètres du navigateur. Ce soi-disant filtre de suivi des cookies
          aide l'utilisateur à sauvegarder quels cookies doivent être définis. Son propre cookie est stocké à cette fin. Si
          vous supprimez vos cookies, vous ne devez pas supprimer ce cookie, car sinon le filtre de suivi des cookies ne peut pas
          reconnaître vos préférences.
        </p>
        <H as="h2">Effacement des données</H>
        <p>
          Les données traitées par moi sont effacées conformément aux articles 17 et 18 RGPD, ou leur traitement est restreint.
          Sauf indication contraire dans le cadre de la politique de protection des données, les données stockées chez moi sont supprimées dès qu'elles ne sont plus nécessaires à leur finalité, et qu'aucune obligation légale de conservation ne s'oppose à la suppression. Lorsque
          les données ne sont pas supprimées parce qu'elles sont nécessaires à d'autres fins légales permises par la législation, leur
          traitement est restreint. En d'autres termes, les données sont bloquées et ne sont pas traitées à d'autres fins. Par exemple,
          cela s'applique aux données devant être conservées en vertu du droit commercial ou fiscal.
        </p>
        <H as="h2">Traitement lié aux affaires</H>
        <p>Je traite également</p>
        <p>– les données contractuelles (par exemple, objet du contrat, durée, catégorie de client).</p>
        <p>– les données de paiement (par exemple, coordonnées bancaires, historique des paiements)</p>
        <p>
          de mes clients, clients potentiels et partenaires commerciaux dans le but de fournir des services contractuels,
          d'assurer le service et la relation client, de réaliser des activités de marketing, de publicité et de recherche de marché.
        </p>
        <H as="h2">Hébergement</H>
        <p>
          Les services d'hébergement que j'utilise servent à fournir les services suivants : services d'infrastructure et de plateforme,
          capacité de calcul, espace mémoire et services de base de données, ainsi que services de sécurité et de
          maintenance technique que j'utilise dans le but d'exploiter cette offre en ligne.
        </p>
        <p>
          Ce faisant, moi ou, le cas échéant, mon fournisseur d'hébergement traitons les données d'inventaire, les coordonnées, les données de contenu,
          les données contractuelles, les données d'utilisation, les données méta et de communication des clients et des clients potentiels ainsi que des visiteurs
          de cette offre en ligne sur la base de nos intérêts légitimes à fournir cette offre en ligne de manière efficace et sécurisée
          conformément à l'article 6 (1, f) RGPD en liaison avec l'article 28 RGPD (conclusion d'un accord de traitement).
        </p>
        <H as="h2">Collecte de données d'accès et de fichiers journaux</H>
        <p>
          Sur la base de mon intérêt légitime au sens de l'article 6 (1, f) RGPD, moi ou, le cas échéant, mon fournisseur d'hébergement
          collectons des données sur tout accès au serveur sur lequel ce service est situé (fichiers journaux du serveur). Les données d'accès comprennent le nom du site web accédé, le fichier, la date et l'heure d'accès, la quantité de données transférées, le rapport d'accès réussi, le type de navigateur ainsi que sa version, le système d'exploitation de l'utilisateur, l'URL de référence (site précédemment visité), l'adresse IP et le fournisseur de demande.
        </p>
        <p>
          Le stockage temporaire de l'adresse IP par le système est nécessaire pour permettre la livraison du site web à
          l'ordinateur de l'utilisateur. L'adresse IP de l'utilisateur doit rester stockée pendant la durée de la session pour permettre cela.
        </p>
        <p>
          Le stockage a lieu dans les fichiers journaux pour garantir la fonctionnalité du site web, avant tout pour analyser une
          attaque (attaque DOS) et l'utilisation abusive des outils fournis. Les données servent également à optimiser le site web et
          à garantir la sécurité de mes systèmes informatiques. Les données ne sont pas évaluées à des fins de marketing à cet égard.
        </p>
        <p>
          Les données sont effacées dès qu'elles ne sont plus nécessaires pour atteindre l'objectif de leur collecte.
          Cela se produit généralement après 30 jours.
        </p>
        <p>
          La collecte de données pour fournir le site web et le stockage des données dans les fichiers journaux sont obligatoires pour
          l'exploitation du site web. En conséquence, l'utilisateur ne peut pas s'y opposer.
        </p>
        <H as="h2">Fourniture de services contractuels</H>
        <p>
          Je traite les données d'inventaire (par exemple, noms et adresses ainsi que coordonnées des clients) et les données contractuelles (par exemple, services utilisés, noms des personnes de contact, informations de paiement) dans le but de remplir mes
          obligations et services contractuels conformément à l'article 6 (1, b) RGPD. Les entrées marquées comme obligatoires
          dans les formulaires en ligne sont nécessaires pour conclure le contrat.
        </p>
        <p>
          Lors de l'utilisation de mes services en ligne, je stocke l'adresse IP et l'heure de l'activité de l'utilisateur respectif.
          Le stockage a lieu sur la base de mes intérêts légitimes, ainsi que pour protéger l'utilisateur contre les abus et autres
          utilisations non autorisées. Ces données ne sont en principe pas divulguées à des tiers, sauf si cela est nécessaire pour
          suivre mes réclamations ou s'il existe une obligation légale à cet égard conformément à l'article 6 (1, c) RGPD.
        </p>
        <p>
          Je traite les données d'utilisation (par exemple, pages visitées de mon offre en ligne, intérêt pour mes produits) et les données de contenu (par exemple,
          entrées dans le formulaire de contact ou profil utilisateur) à des fins promotionnelles dans un profil utilisateur pour montrer aux utilisateurs, par
          exemple, des informations sur les produits en fonction des services précédemment utilisés par eux.
        </p>
        <p>
          Les données sont supprimées à l'expiration de la garantie légale et des obligations comparables, et la nécessité de conserver les
          données est vérifiée tous les trois ans. En cas d'obligations légales d'archivage, la suppression a lieu à leur expiration.
          Les détails de tout compte client restent jusqu'à sa suppression.
        </p>
        <H as="h2">Administration, comptabilité, organisation de bureau, gestion des contacts</H>
        <p>
          Je traite les données dans le cadre de tâches administratives ainsi que de l'organisation de mon activité, de la comptabilité et
          du respect des obligations légales telles que l'archivage. En tant que tel, je traite les mêmes données que celles que je traite lorsque
          je fournis mes services contractuels. Les bases de traitement sont l'article 6 (1, c) RGPD et l'article 6 (1, f)
          RGPD. Les clients, les prospects, les partenaires commerciaux et les visiteurs du site web sont concernés par
          le traitement. L'objectif et l'intérêt du traitement résident dans l'administration, la comptabilité, l'organisation
          de bureau et l'archivage des données, c'est-à-dire des tâches servant à maintenir mes activités commerciales, à remplir mes
          tâches et à fournir mes services. L'effacement des données concernant les services contractuels et la communication
          correspond aux détails indiqués dans ces activités de traitement.
        </p>
        <p>
          À ce titre, je divulgue ou transmets des données à l'administration fiscale, à des consultants tels que des conseillers fiscaux ou des auditeurs, et
          à d'autres collecteurs de charges et prestataires de services de paiement.
        </p>
        <p>
          De plus, en fonction de mes intérêts commerciaux, je conserve des informations sur les fournisseurs, les organisateurs et d'autres
          partenaires commerciaux, par exemple dans le but de prendre contact ultérieurement. En principe, je partage ces
          données relatives aux entreprises de manière permanente.
        </p>
        <H as="h2">Analyse commerciale et recherche de marché</H>
        <p>
          Afin de pouvoir exploiter mon activité commercialement et identifier les tendances du marché ainsi que les souhaits des clients et des utilisateurs,
          j'analyse les données disponibles sur les processus commerciaux, les contrats, les demandes, etc. Ce faisant,
          je traite les données d'inventaire, les données de communication, les données contractuelles, les données de paiement, les données d'utilisation et les méta-données sur
          la base de l'article 6 (1, f) RGPD, où les personnes concernées incluent les clients, les clients potentiels, les partenaires commerciaux et
          les visiteurs et utilisateurs de l'offre en ligne.
        </p>
        <p>
          L'analyse est effectuée dans le but d'évaluation commerciale, de marketing et de recherche de marché. Cela me permet de considérer les profils des utilisateurs enregistrés avec des détails tels que leurs processus d'achat. L'analyse
          me sert à augmenter la convivialité et à optimiser mon offre et l'efficacité de mon activité. L'analyse me sert
          uniquement et n'est pas divulguée à l'extérieur, sauf s'il s'agit d'une analyse anonymisée avec des valeurs résumées.
        </p>
        <p>
          Lorsque cette analyse ou ces profils sont personnels, ils sont effacés ou anonymisés à la résiliation de l'utilisateur, sinon après deux ans à compter de la résiliation du contrat. De plus, une analyse commerciale globale et une détermination générale des tendances sont produites dans la mesure du possible.
        </p>
        <H as="h2">Fonction d'inscription par email</H>
        <p>
          Les utilisateurs ont la possibilité de s'abonner à mon contenu avec leur email. Les utilisateurs reçoivent les
          informations obligatoires requises lors de l'inscription. Les informations saisies lors de l'inscription sont utilisées pour profiter de l'offre. Les utilisateurs peuvent recevoir des emails relatifs aux offres ou aux modifications de la portée de l'offre ou aux conditions techniques. Si les utilisateurs ont annulé leur compte utilisateur, leurs données concernant le compte utilisateur sont
          effacées, sous réserve de la nécessité de les conserver pour des raisons commerciales ou fiscales conformément à
          l'article 6 (1, c) RGPD. Il incombe aux utilisateurs de sauvegarder leurs données avant la fin du contrat en
          cas d'annulation. Je suis autorisé à effacer irrévocablement toutes les données de l'utilisateur stockées pendant le
          contrat.
        </p>
        <p>
          Lorsque mes fonctions d'enregistrement et de connexion sont utilisées, ainsi que lorsque le compte utilisateur est utilisé, je stocke
          l'adresse IP et l'heure de l'activité utilisateur respective. Le stockage a lieu non seulement sur la base de mes
          intérêts légitimes, mais aussi pour protéger l'utilisateur contre les abus et autres utilisations non autorisées. Ces données ne sont
          en principe pas divulguées à des tiers sauf si nécessaire pour suivre mes réclamations ou s'il existe une obligation légale à cet égard conformément à l'article 6 (1, c) RGPD.
        </p>
        <H as="h2">Contact</H>
        <p>
          Lorsque vous me contactez (par exemple au moyen du formulaire de contact, par email, téléphone ou via les réseaux sociaux), les
          informations de l'utilisateur sont traitées pour traiter la demande de contact conformément à l'article 6 (1, b) RGPD.
          Les informations de l'utilisateur peuvent être stockées dans un système de gestion de la relation client (CRM) ou un système de gestion des demandes comparable.
        </p>
        <p>
          Je supprime les demandes dès qu'elles ne sont plus nécessaires. Je vérifie la nécessité tous les deux ans et les
          obligations légales d'archivage s'appliquent également.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default PrivacyPolicy;









